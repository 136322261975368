.side-menu {
  background-color: $light-gray;
  // border: 1px solid $white;
  box-shadow: 0 20px 179px rgba(0, 0, 0, 0.08);
  // border-radius: 33px;
  min-width: 250px;
  max-width: 250px;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: #0B213D;
  box-shadow: 0px 20px 179px rgba(0, 0, 0, 0.08);
  border-radius: 0px 10px 10px 0px;

  .side-menu-header-button {
    background: transparent;
    position: absolute;
    right: 5%;
    top: 10px;
    border: none;
    display: none;

    svg {
      font-size: 20px;
      color: $white;
    }
  }

  .side-menu-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px 70px 20px 70px !important;
    width: 100%;

    img {
      width: 100%;
    }

    .logo {
      width: 76px;
      height: 76px;
      background-color: $primary;
      position: absolute;
      top: 0;
      left: 0;
      border-bottom-right-radius: 30px;
      flex-direction: row;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: contain;
        width: 100%;
      }
    }

    .company-name {
      color: $white;
      font-family: $font-bold;
      text-transform: capitalize;
      // margin-left: 20px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: anywhere;
      font-size: 14px;
      line-height: 113.6%;
      // margin-left: 5px;
      text-align: center;
    }
  }

  .side-menu-body {
    flex: 1;
    position: relative;
    z-index: 1;
    overflow-y: auto;
    @include scroll-y;
    // margin: 35px 10px;

    &.separate-list {
      flex: 0;
      margin: 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .side-menu-list-item {
      margin-bottom: 0px !important;

      a {
        display: flex;
        align-items: center;
        // border-radius: 18px;
        height: 50px;
        font-size: 12px;
        color: $white;
        padding: 0 25px !important;
        font-family: $font-light;
        // letter-spacing: -2px;
        text-decoration: none;

        svg {
          width: 20px;
          margin-right: 15px;
          color: $white;
        }

        .icon-class {
          width: 30px;
          height: 18px;
          color: #ffffff;
        }

        &.active {
          // background: rgba(0, 89, 247, 0.06);
          background-color: $primary;
          font-family: $font-light;

          svg {
            color: $white;
          }
        }

        img {
          margin-right: 10px;
        }
      }

      div {
        display: flex;
        align-items: center;
        border-radius: 18px;
        height: 60px;
        font-size: 14px;
        color: $light-black;
        padding: 0 20px;
        font-family: $font-regular;

        svg {
          width: 20px;
          font-size: 22px;
          margin-right: 15px;
          color: $darken-gray;
        }

        &.active {
          background: rgba(0, 89, 247, 0.06);
          font-family: $font-bold;

          svg {
            color: $primary;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .side-menu-footer {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 25px;
    gap: 8px;

    .side-menu-footer-text {
      font-family: $font-regular;
      font-size: 10px;
      line-height: 12px;
      color: $dark-gray;
      margin: 0px;
      display: flex;

      .sidemenuarrow {
        height: 10px;
        width: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .side-menu-footer-link {
      color: $white;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-decoration: none;
       
      &:hover {
        color: $button-filled;
      }
    }
  }

  .side-menu-background-image {
    height: 385px;
    width: 237px;
    left: 0;
    right: 0;
    bottom: -5px;
    position: absolute;
    margin: auto;
  }
}

.user-detail {
  display: flex;
  margin: 0 25px 40px;
  background: linear-gradient(180deg, #323f57 0%, rgba(50, 63, 87, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 8px;
  padding: 25px 30px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .user-image {
    width: 115px;
    height: 115px;
    margin: 0 auto 15px;
    border: 2px solid $primary;
    border-radius: 50%;
  }

  svg {
    margin-bottom: 20px;
  }

  h3 {
    margin: 0;
    font-size: 14px !important;
    text-align: center;
    font-family: $font-medium;
    line-height: 22px;
    color: $white;
  }

  h5 {
    font-size: 10px !important;
    text-align: center;
    font-family: $font-regular;
    line-height: 22px;
    color: $white;
    margin-bottom: 0;
  }

  img {
    width: 33px;
    height: 62px;
    margin-right: 10px;
    color: $gray-text;
  }

  .user-wallet {
    color: $gray-text;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      size: 16px;
      line-height: 22px;
      margin: 0;
    }
  }

  // &.active {
  //   background: rgba(0, 89, 247, 0.06);
  //   font-family: $font-bold;

  //   img {
  //     color: $primary;
  //   }
  // }

  .side-menu-list-item {
    a {
      justify-content: center;
    }
  }
}

.activeTag {
  background-color: transparent !important;
  border: 1px solid green;
  padding: 2px 8px;
  margin-left: auto !important;
}

// .separator {
//   width: 100%;
//   height: 1px;
//   background-color: $dark-gray;
//   border: none;
//   border-radius: 50%;
//   margin: 20px 0 0;
// }

// .logo-white{
//   height: 50px;
//   width: 50px;
// }