.navbar {
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 20px 10px;
  display: flex;
  position: relative;
  // margin-bottom: 25px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  // overflow: hidden;

  .menu-button {
    margin-right: 15px;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 22px;
    display: none;
    color: $primary;
    margin-bottom: 10px;
    align-self: center
  }

  .ajmanBankLogo {
    width: 200px
  }

  .back-button {
    @extend .menu-button;
    display: flex;
    cursor: pointer;
  }

  .main-nav {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .navbar-left {
      flex: 2;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;
      flex-direction: column;
      min-width: 300px;
      flex-wrap: wrap;

      span {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;

        svg {
          margin-bottom: 15px;
        }

      }

      h1 {
        margin: 0 0 10px 0;
        font-family: $font-semiBold !important;
        font-size: 24px;
        color: $black_2;
        // overflow: hidden;
        // text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 113.6%;
        letter-spacing: -0.02em;
        white-space: initial;
        // word-wrap: break-word
      }

      p {
        margin: 0;
        font-family: $font-regular;
        color: $light-black;
        font-size: 12px;
        line-height: 113.6%;
        letter-spacing: -0.02em;
      }
    }

    .navbar-right {
      // flex: 1;
      align-self: flex-end;
      display: flex;
      margin-left: 20px;
      margin-bottom: 10px;

      .notificationIcon {
        margin-right: 15px;
        cursor: pointer;
        // padding: 10px;
        // border: 1px solid green;
        border-radius: 12%;

        &:hover {
          // background-color: rgb(221, 243, 221);
        }
      }
    }
  }
}

.singleNotification {
  &:hover {
    cursor: pointer;
  }

  span {
    font-weight: 500;
    color: $primary;
  }

  p {
    color: grey;
  }

  // background-color: red;
  margin:10px 0px;
  padding:10px 10px;

  &:nth-child(even) {
    // background-color: #e1edf6;
  }
}