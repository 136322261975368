.ant-steps {
  margin: 10px 0;
  overflow-x: auto;
  
  .ant-steps-item {
    .ant-steps-item-container {
      .ant-steps-item-content {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;

        .ant-steps-item-title {
          // font-size: 12px;
          // max-width: 100px;
        }
        .ant-steps-item-description {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // font-size: 10px;
          // max-width: 80px;
        }
      }
    }
  }
}
